<template>
  <v-row>
    <v-col>
      <div class="line"></div>
      <v-tabs v-model="tab" color="white" show-arrows mobile-breakpoint="960">
        <v-tabs-slider color="rouge"></v-tabs-slider>
        <v-tab v-for="step in 5" :href="'#tab-' + step" :key="step" active-class="rouge--text" class="subheading">
          <span v-if="step === 1">캠페인 분류</span>
          <span v-else-if="step === 2">기본 정보</span>
          <span v-else-if="step === 3">상세 정보</span>
          <span v-else-if="step === 4">채널 정보</span>
          <span v-else-if="step === 5">사진 관리</span>
          <v-tooltip v-if="showIcon(step)" bottom max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense v-bind="attrs" v-on="on">mdi-help-rhombus-outline</v-icon>
            </template>
            필수 입력 정보가 누락되었습니다.
          </v-tooltip>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <div class="line"></div>
        <v-tab-item v-for="step in 5" :value="'tab-' + step" :key="step">
          <Page1 v-if="step === 1" :camData="camData"></Page1>
          <StorePage2 v-if="(camData.category || {}).firstName === '매장' && step === 2" :camData="camData" :option="option" @updateEvent="updateEvent"></StorePage2>
          <ProductPage2 v-else-if="(camData.category || {}).firstName === '제품' && step === 2" :camData="camData" :option="option" @updateEvent="updateEvent"></ProductPage2>
          <ServicePage2 v-else-if="step === 2" :camData="camData" :option="option" @updateEvent="updateEvent"></ServicePage2>
          <StorePage3 v-if="(camData.category || {}).firstName === '매장' && step === 3" :camData="camData" :option="option" @updateEvent="updateEvent"></StorePage3>
          <ProductPage3 v-else-if="(camData.category || {}).firstName === '제품' && step === 3" :camData="camData" :option="option" @updateEvent="updateEvent"></ProductPage3>
          <ServicePage3 v-else-if="step === 3" :camData="camData" :option="option" @updateEvent="updateEvent"></ServicePage3>
          <Page4 v-if="step === 4" :camData="camData" :option="option" @updateEvent="updateEvent"></Page4>
          <Page5 v-if="step === 5" :camData="camData" :option="option" @updateEvent="updateEvent"></Page5>

          <v-row class="px-6" v-if="step === 1">
            <v-col cols="12" class="body-1 my-2" style="color: #484848;">캠페인 복사 </v-col>
            <v-col md="5">
              <v-btn block color="primary" class="mb-6 ml-0" @click="showCopyCampaignDlg=true">
                현재 캠페인 복사하기
              </v-btn>
            </v-col>
          </v-row>
          <v-btn v-else block color="primary" class="ma-0" @click="registerCam">
            저장
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
      <v-row justify="center">
        <v-dialog v-model="showCopyCampaignDlg" content-class="white-two" persistent max-width="400px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showCopyCampaignDlg=false">close</v-icon><br>
                <h2 class="mt-2 font-weight-bold headline">새 캠페인에 복사</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row >
                <v-col cols="12">
                  <div class="body-1 mt-0 mb-6">
                    현재 캠페인의 내용을 새 캠페인에 복사합니다.<br>
                    새 캠페인의 이름을 입력해주세요.
                  </div>
                </v-col>
                <v-col>
                  <v-text-field v-model="newCampaignName" name="newCampaignName" type="text"
                                v-validate="'required'"
                                :rules="[errors.first('newCampaignName') || !errors.has('newCampaignName')]"
                                placeholder="캠페인 명"
                                hide-details
                                single-line outlined solo flat required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="rouge" outlined flat @click.native="showCopyCampaignDlg=false; ">닫기</v-btn>
              <v-btn class="rouge white-two--text" flat @click="showCopyCampaignDlg=false; copyCampaign()">복사하기</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import validator from 'validator';

  export default {
    name: "Modify",
    mixins: [userMixin],
    props: ['campaign'],
    components: {
      Page1: () => import('@/components/campaign/modcam/Page1'),
      StorePage2: () => import('@/components/campaign/modcam/store/Page2'),
      StorePage3: () => import('@/components/campaign/modcam/store/Page3'),
      ServicePage2: () => import('@/components/campaign/modcam/service/Page2'),
      ServicePage3: () => import('@/components/campaign/modcam/service/Page3'),
      ProductPage2: () => import('@/components/campaign/modcam/product/Page2'),
      ProductPage3: () => import('@/components/campaign/modcam/product/Page3'),
      Page4: () => import('@/components/campaign/modcam/Page4'),
      Page5: () => import('@/components/campaign/modcam/Page5')
    },
    data() {
      return {
        tab: 1,
        step: 1,
        camData: {
          category: {
            firstName: '매장',
            first: '1cbTA11dQev5s531ukil',
            secondName: '',
            thirdName: ''
          }
        },
        showCopyCampaignDlg: false,
        newCampaignName: '',
        hasChanged: false
      }
    },
    computed: {
    },
    watch: {
      campaign(value) {
        this.camData = JSON.parse(JSON.stringify(value));
      },
      hasChanged(value) {
        this.$emit('hasChanged', value);
      }
    },
    methods: {
      updateEvent(e) {
        this.hasChanged = true;
        // this._.merge(this.camData, e);
        Object.keys(e).forEach((key) => {
          this.camData[key] = e[key];
        });
        // if (e) {
        //   this.$store.commit('setDirty', true);
        // }
      },
      async save () {
        try {
          this.hasChanged = false;
          await this.axios.put('/campaign?id=' + this.camData.id, {...this.camData, delay: true});
          window.setTimeout(async () => {
            await this.$store.dispatch('getCampaignInfo', {email: this.user.email});
          }, 1000);
          await this.$store.commit('setDirty', false);
        }
        catch (e) {
          console.log(e);
        }
      },
      async registerCam() {
        if (this.showIcon(parseInt(this.tab.split('-')[1]))) {
          this.$store.commit('setMsg', '필수 입력 항목을 입력해주세요.');
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        if (((this.camData.naver || {}).purchaseLink && !validator.isURL((this.camData.naver || {}).purchaseLink.trim())) ||
            ((this.camData.insta || {}).purchaseLink && !validator.isURL((this.camData.insta || {}).purchaseLink.trim()))) {
          this.$store.commit('setMsg', '올바른 URL 형식이 아닙니다.');
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        if(!this.$store.getters.loading) {
          this.$store.commit('setLoading', true);

          if ((this.$refs.page4 || {}).addKeyword) {
            await this.$refs.page4.addKeyword();
          }
          else if (this.$refs.page4 && (this.$refs.page4 || {})[0].addKeyword) {
            await this.$refs.page4[0].addKeyword();
          }
          if ((this.$refs.page4 || {}).addHashtag) {
            await this.$refs.page4.addHashtag();
          }
          else if (this.$refs.page4 && (this.$refs.page4 || {})[0].addHashtag) {
            await this.$refs.page4[0].addHashtag();
          }

          try {
            ['naver', 'insta'].forEach((channel) => {
              if(this.camData[channel]) {
                if(!this.camData[channel].revisitPeriod) {
                  this.camData[channel].revisitPeriod = ((this.camData.category.firstName === '매장') ? 60 : 60);
                }
                if(this.camData[channel].number > 0) {
                  this.camData[channel].current = 0;
                }
                if(this.camData[channel].blackPayment === undefined) {
                  this.camData[channel].blackPayment = 0;
                }
                if(this.camData[channel].redPayment === undefined) {
                  this.camData[channel].redPayment = 0;
                }
                if(this.camData[channel].yellowPayment === undefined) {
                  this.camData[channel].yellowPayment = 0;
                }
                if(this.camData.category.firstName === '제품' && this.camData[channel].deliveryCharge === undefined) {
                  this.camData[channel].deliveryCharge = 0;
                }
              }
            });

            delete this.camData.verified;

            await this.save();

            this.$store.commit('setMsg', '저장이 완료되었습니다.');
            this.$store.commit('setShowSnackbar', true);
          }
          catch (err) {
            console.log(err);
          }

          this.$store.commit('setLoading', false);
        }
      },
      showIcon(step) {
        let fields = this.$requiredMap[this.camData.category.firstName][this.camData.type];
        if (step === 2) {
          fields = this._.intersection(['local', 'serviceName', 'address', 'contacts', 'companyName'], fields);
          return !fields.every((field) => {
            return this.camData[field];
          })
        }
        else if (step === 3) {
          fields = this._.difference(fields, ['local', 'name', 'address', 'contacts', 'companyName', 'thumbnail', 'thumbnails']);
          if (this.camData.deliveryMethod === 'PR') {
            fields.push('storeUrl');
          }
          return !fields.every((field) => {
            return this.camData[field];
          })
        }
        else if (step === 4) {
          return this.$channel.some((channel) => {
            return this.camData[channel] && !this.$requiredMap[channel][this.camData.type].every((field) => {
              if (field === 'mission' || field === 'keywords') {
                return (this.camData[channel] || {})[field] && (this.camData[channel] || {})[field].length > 0;
              }
              else if (field === 'reward' || field === 'rewardPrice') {
                return this.camData.deliveryMethod !== 'PR' || (this.camData.deliveryMethod === 'PR' && (this.camData[channel] || {})[field]);
              }
              return (this.camData[channel] || {})[field];
            })
          });
        }
        else if (step === 5) {
          fields = this._.intersection(['thumbnail', 'thumbnails'], fields);
          return !fields.every((field) => {
            return !!this.camData[field];
          })
        }
        else {
          return false;
        }
      },
      async copyCampaign() {
        let newCampaign = this._.cloneDeep(this.camData);
        newCampaign.name = this.newCampaignName;
        delete newCampaign.status;
        delete newCampaign.createdAt;
        delete newCampaign.updatedAt;
        ['naver', 'insta', 'youtube'].map((channel) => {
          if (newCampaign[channel]) {
            delete newCampaign[channel].id;
            delete newCampaign[channel].createdAt;
            delete newCampaign[channel].updatedAt;
          }
        });

        await this.axios.post('/campaign/copy', newCampaign);

        this.$store.commit('setMsg', '저장이 완료되었습니다.');
        this.$store.commit('setShowSnackbar', true);

        await this.$store.dispatch('getCampaignInfo', {id: this.user.id});
        this.$router.push('/campaign/search');
      },
      closeHandler(e) {
        if (this.hasChanged) {
          e.preventDefault();
          // e.returnValue = true;
          let confirmationMessage = "unload";
          (e || window.event).returnValue = confirmationMessage;
          return confirmationMessage;
        }
      }
    },
    created() {
      this.camData = JSON.parse(JSON.stringify(this.campaign));
      this.newCampaignName = this.campaign.name + ' - 복사본';
      this.hasChanged = false;
      // this.$store.commit('setDirty', false);
      window.addEventListener('beforeunload', this.closeHandler);
    },
    beforeCreate() {
      this.axios.get('/company/option').then(option => {
        this.option = option.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.closeHandler);
    }
  };
</script>